import React from "react";

import type { HeadFC } from "gatsby";
import { graphql } from "gatsby";

import { Box } from "@mui/system";

import { Layout, BaseHead, CreationList } from "../components";
import { Typography } from "@mui/material";

const CategoryPage = ({ data: { category, allCreation } }: any) => {
  return (
    <Layout category={category}>
      <Typography variant="h3" component="h1" sx={{ my: 2 }}>
        {category?.name}
      </Typography>
      {category?.description?.childMarkdownRemark?.html ? (
        <main
          dangerouslySetInnerHTML={{
            __html: category?.description?.childMarkdownRemark?.html,
          }}
        />
      ) : null}

      <Box>
        <CreationList creations={allCreation?.nodes || []} />
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query CategoryPage($key: String) {
    category(key: { eq: $key }) {
      key
      name
      type
      description {
        childMarkdownRemark {
          html
        }
      }
    }
    allCreation(filter: { category: { eq: $key } }) {
      nodes {
        id
        height
        category
        key
        name
        images {
          alt
          title
          src {
            childImageSharp {
              gatsbyImageData(
                height: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                backgroundColor: "rgba(255,255,255,1)"
              )
            }
          }
        }
      }
    }
  }
`;

export const Head: HeadFC = ({
  data: {
    category: { name },
  },
}: any) => <BaseHead title={name} />;

export default CategoryPage;
